import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';

import styles from './styles.module.scss';
import { EntityFieldReferenceSelect } from '~/common/components/EntityFieldReferenceSelect';
import InfoPageFileUpload from '~/pages/infoPage/components/InfoPageFileUpload';

const InfoPageColorSolutions: FC<IEntityFieldProps> = ({
  label,
  name,
  value,
  handler,
  error,
  entity,
  fields,
  options,
  data,
}) => {
  const referenceData = useMemo(
    () => (options ? Object.values((options!.referenceData as Record<string, any>) || {}) : []),
    [options!.referenceData]
  );

  const initialSelectedSolutions = useMemo<number[]>(
    () => (value ? value.map(({ id }) => id as number) : []),
    [value]
  );

  const [selectedSolutions, setSelectedSolutions] = useState<number[]>(initialSelectedSolutions);
  const initialSolutionModels = useMemo(
    () => (value ? selectedSolutions.map((id) => value.find((el) => el.id === id)) : []),
    [value, selectedSolutions]
  );

  const [solutionModels, setSolutionModels] =
    useState<{ id: number; file: File }[]>(initialSolutionModels);

  const handleSelectChange = useCallback(
    (id: string) => setSelectedSolutions((prevState) => [...new Set([...prevState, +id])]),
    [setSelectedSolutions]
  );

  const handleSolutionDelete = useCallback(
    (deletedId: number) => {
      setSelectedSolutions((prevState) => prevState.filter((id) => id !== deletedId));
      setSolutionModels((prevState) => prevState.filter(({ id }) => id !== deletedId));
    },
    [setSelectedSolutions]
  );

  const handleSolutionFileChange = useCallback(
    (id: number) => (file: File) => {
      const solutionModel = solutionModels.some((el) => el.id === id);

      if (solutionModel) {
        setSolutionModels((prevState) =>
          prevState.map((el) => (el.id === id ? { ...el, file } : el))
        );
      } else {
        setSolutionModels((prevState) => [...prevState, { id, file, previewImg: null }]);
      }
    },
    [solutionModels, setSolutionModels]
  );

  const normalizedOptions = useMemo(
    () =>
      referenceData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.name,
        }),
        {}
      ),
    [referenceData]
  );

  useEffect(() => {
    if (handler) handler(solutionModels);
  }, [solutionModels]);

  const getSolutionName = useCallback(
    (id: number) => referenceData.find((el) => el.id === id)?.name,
    [referenceData]
  );

  const getModelName = useCallback(
    (id: number) => {
      const file = data?.[name]?.find((el) => el.id === id) || value?.find((el) => el.id === id);
      return file?.modelName || file?.fileName || file?.file?.fileName;
    },
    [data?.[name], value]
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Цветовые решения
      </Typography>
      <EntityFieldReferenceSelect
        fields={fields}
        label={label}
        name={name}
        value={value}
        handler={handleSelectChange}
        error={error}
        isEditing
        entity={entity}
        options={normalizedOptions}
      />
      <div className={styles.selectedSolutions}>
        {selectedSolutions.map((id) => (
          <Chip
            key={id}
            label={getSolutionName(id)}
            onDelete={() => handleSolutionDelete(id)}
            color="primary"
          />
        ))}
      </div>
      <div className={styles.selectedSolutions}>
        {selectedSolutions.map((id) => (
          <div key={id} className={styles.selectedSolutions_map}>
            <InfoPageFileUpload
              fields={fields}
              name={`${id}`}
              label={`2D модель для цвета: ${getSolutionName(id)}`}
              isEditing
              handler={handleSolutionFileChange(id)}
              options={options}
              fileNameFromValue={getModelName(id)}
              data={value.find((item) => item.id === id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { InfoPageColorSolutions };
