import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import styles from './styles.module.scss';
import {
  SectionFileUpload,
  SectionImageUpload,
} from '~/pages/section/components/SectionFileUpload';
import { ALLOWED_IMAGE_EXTENSIONS, MAX_IMAGE_SIZE } from '~/pages/section/constants';
import { EntityFieldReferenceSelect } from '~/common/components/EntityFieldReferenceSelect';

const SectionColorSolutions: FC<IEntityFieldProps> = ({
  label,
  name,
  value,
  handler,
  error,
  entity,
  fields,
  options,
  data,
}) => {
  const referenceData = useMemo(
    () => (options ? Object.values((options!.referenceData as Record<string, any>) || {}) : []),
    [options!.referenceData]
  );
  const isCreating = window.location.pathname.includes('create');
  const initialSelectedSolutions = useMemo(
    () =>
      value && !isCreating ? value.map(({ id, colorSolutionId }) => id ?? colorSolutionId) : [],
    []
  );
  const [selectedSolutions, setSelectedSolutions] = useState<number[]>(initialSelectedSolutions);

  const initialSolutionModels = useMemo(
    () =>
      value
        ? selectedSolutions.map((id) => {
            const currModel = value.find((el) => el.colorSolutionId === id);
            return {
              id,
              file: currModel?.model3D || currModel?.file,
              previewImg: currModel?.previewImg,
            };
          })
        : [],
    [value, selectedSolutions]
  );

  const [solutionModels, setSolutionModels] =
    useState<{ id: number; file: File | null; previewImg: File | null }[]>(initialSolutionModels);

  const handleSelectChange = useCallback(
    (id: string) => setSelectedSolutions((prevState) => [...new Set([...prevState, +id])]),
    [setSelectedSolutions]
  );

  const handleSolutionDelete = useCallback(
    (deletedId: number) => {
      setSelectedSolutions((prevState) => prevState.filter((id) => id !== deletedId));
      setSolutionModels((prevState) => prevState.filter(({ id }) => id !== deletedId));
    },
    [setSelectedSolutions]
  );

  const handleSolutionFileChange = useCallback(
    (id: number) => (file: File) => {
      const solutionModel = solutionModels.some((el) => el.id === id);

      if (solutionModel) {
        setSolutionModels((prevState) =>
          prevState.map((el) => (el.id === id ? { ...el, file } : el))
        );
        return;
      }

      setSolutionModels((prevState) => [...prevState, { id, file, previewImg: null }]);
    },
    [solutionModels, setSolutionModels]
  );

  const handleSolutionImageChange = useCallback(
    (id: number) => (file: File) => {
      setSolutionModels((prevState) =>
        prevState.map((el) => (el.id === id ? { ...el, previewImg: file } : el))
      );
    },
    [solutionModels, setSolutionModels]
  );

  const normalizedOptions = useMemo(
    () =>
      referenceData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id]: curr.name,
        }),
        {}
      ),
    [referenceData]
  );

  useEffect(() => {
    handler!(solutionModels);
  }, [solutionModels]);

  const getSolutionName = useCallback(
    (id: number) => referenceData.find((el) => el.id === id)?.name,
    [referenceData]
  );
  const getModelName = useCallback(
    (id: number) => {
      const file =
        data?.[`${name}Url`]?.find((el) => el.id === id) || value?.find((el) => el.id === id);
      return file?.originalName || file?.fileName || file?.file?.fileName;
    },
    [data?.[`${name}Url`], value]
  );

  const getImageName = useCallback(
    (id: number) => {
      const file =
        data?.[`${name}Url`]?.find((el) => el.id === id) || value?.find((el) => el.id === id);
      return file?.previewOriginalName || file?.fileName || file?.file?.fileName;
    },
    [data?.[`${name}Url`], value]
  );

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Цветовые решения
      </Typography>
      <EntityFieldReferenceSelect
        fields={fields}
        label={label}
        name={name}
        value={value}
        handler={handleSelectChange}
        error={error}
        isEditing
        entity={entity}
        options={normalizedOptions}
      />
      <div className={styles.selectedSolutions}>
        {selectedSolutions.map((id) => (
          <Chip
            key={id}
            label={getSolutionName(id)}
            onDelete={() => handleSolutionDelete(id)}
            color="primary"
          />
        ))}
      </div>
      <div className={styles.selectedSolutions}>
        {selectedSolutions.map((id) => (
          <div key={id} className={styles.selectedSolutions_map}>
            <SectionFileUpload
              fields={fields}
              name={`${id}`}
              label={`3D модель для цвета: ${getSolutionName(id)}`}
              isEditing
              handler={handleSolutionFileChange(id)}
              options={options}
              fileNameFromValue={getModelName(id)}
            />
            {solutionModels.find((model) => model.id === id)?.file && (
              <SectionImageUpload
                fields={fields}
                name={name}
                label={`Изображение для цвета: ${getSolutionName(id)}`}
                isEditing
                handler={handleSolutionImageChange(id)}
                options={{ allowedMimeType: ALLOWED_IMAGE_EXTENSIONS, maxSize: MAX_IMAGE_SIZE }}
                fileNameFromValue={getImageName(id)}
                data={data?.colorSolutionsUrl?.find((el) => el.id === id)?.previewUrl}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { SectionColorSolutions };
