// eslint-disable-next-line no-shadow
export enum InfoPageModulesName {
  common = 'Общее',
  characteristics = 'Характеристики',
  model = 'Интерактивная модель',
}

// eslint-disable-next-line no-shadow
export enum InfoPageModulesUrls {
  common = 'common',
  characteristics = 'characteristics',
  model = 'model',
}

export const infoPageModules = [
  {
    title: InfoPageModulesName.common,
    url: InfoPageModulesUrls.common,
    fields: [
      'iconUrl',
      'name',
      'status',
      'shortDescription',
      'fullDescription',
      'imageUrl',
      'backgroundUrl',
      'icon',
      'image',
      'background',
    ],
  },
  {
    title: InfoPageModulesName.characteristics,
    url: InfoPageModulesUrls.characteristics,
    fields: ['sectionId', 'fold', 'glassUnit', 'fittings', 'color'],
  },
  {
    title: InfoPageModulesName.model,
    url: InfoPageModulesUrls.model,
    fields: ['colorSolutions', 'colorSolutionList', 'fittingSolutions', 'fittingSolutionList'],
  },
];

export const MAX_ICON_SIZE = 1024 * 1024 * 5;
export const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
export const MAX_BACKGROUND_SIZE = 1024 * 1024 * 10;
export const MAX_MODEL_SIZE = 1024 * 1024 * 10;
export const ALLOWED_ICON_EXTENSIONS = ['image/png'];
export const ALLOWED_IMAGE_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
export const ALLOWED_BACKGROUND_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
export const ALLOWED_MODEL_EXTENSIONS = ['image/png'];
export const MAX_NAME_LENGTH = 200;
export const MAX_SHORT_DESCRIPTION_LENGTH = 100;
export const MAX_FULL_DESCRIPTION_LENGTH = 1000;
export const MAX_FOLD_LENGTH = 10;
export const MAX_GLASS_UNIT_LENGTH = 100;
export const MAX_FITTINGS_LENGTH = 100;
export const MAX_COLOR_LENGTH = 100;
