import { Config } from 'icerockdev-admin-toolkit';
import auth from '~/auth';
import theme from '~/config/theme';
import Account from '~/pages/account';
import User from '~/pages/user';
import ColorSolution from '~/pages/colorSolution';
import { CustomNotifications } from '~/common/modules/Notification';
import Section from '~/pages/section';
import FittingSolution from '~/pages/fittingSolution';
import InfoGroup from '~/pages/infoGroup';
import InfoPage from '~/pages/infoPage';

class CustomConfig extends Config {
  notifications = new CustomNotifications();
}

export default new CustomConfig({
  host: process.env.REACT_APP_BASE_URL,
  logo: '/images/logo.svg',
  auth,
  theme,
  pages: [User, Account, ColorSolution, FittingSolution, Section, InfoPage, InfoGroup],
});
