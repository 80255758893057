import {
  AuthProvider,
  JWTAuthProvider,
  WithTokenFunction,
  UNAUTHORIZED,
  EMPTY_USER,
} from 'icerockdev-admin-toolkit';
import { action, flow } from 'mobx';
import Layout from '~/auth/components/Layout';
import {
  authLogoutFn,
  authPasswUpdateFn,
  authPasswRestoreFn,
  authRequestFn,
  tokenRefreshFn,
} from '~/auth/api';
import { UserRoles } from '~/pages/user/constants';
import { ResetPassword } from '~/common/components/ResetPassword';

const EMPTY_TOKENS = {
  access: '',
  refresh: '',
};

class CustomJWTAuthProvider extends JWTAuthProvider {
  @action
  sendAuthPasswRestore = (email: string) => {
    this.sendAuthPasswRestoreCancel();

    this.sendAuthPasswRestoreInstance = flow(function* sendAuthPasswRestore(this: AuthProvider) {
      if (!this.authPasswRestoreFn) return;

      this.isLoading = true;

      try {
        const response = yield this.authPasswRestoreFn(email).catch(() => null);

        if (!response || response.error) {
          throw new Error(response.error);
        }

        this.parent?.notifications.showSuccess(
          'На привязанную к данному логину электронную почту отправлено сообщение с ссылкой для восстановления пароля'
        );
        this.parent?.history.push('/');
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  sendAuthPasswUpdate = (token: string, password: string) => {
    this.sendAuthPasswRestoreCancel();

    this.sendAuthPasswUpdateInstance = flow(function* sendAuthPasswUpdate(this: AuthProvider) {
      if (!this.authPasswUpdateFn) return;

      this.isLoading = true;

      try {
        if (!token.trim()) {
          throw new Error('Token is empty');
        }

        if (this.passwordValidator && this.passwordValidator(password)) {
          throw new Error(this.passwordValidator(password));
        }

        const response = yield this.authPasswUpdateFn(token, password).catch(() => null);

        if (!response || response.error) {
          throw new Error(response.error);
        }

        this.parent?.notifications.showSuccess('Смена пароля произошла успешно');
        this.parent?.history.push('/');
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  withToken: WithTokenFunction = async (req: any, args: any) =>
    req({
      ...args,
      token: `Bearer ${this.tokens.access}`,
    })
      .then((result: any) => {
        if (result?.error === UNAUTHORIZED) {
          try {
            const tokens = this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }
        }
        return result;
      })
      .catch(async (e: Error) => {
        if (e?.message.indexOf('401') !== -1 || e?.message.indexOf('400') !== -1) {
          this.user = EMPTY_USER;
          this.tokens = EMPTY_TOKENS;
          return e;
        }
        if (!this.tokenRefreshFn) {
          console.warn('tokenRefreshFn not specified');
          throw new Error(e.message);
        }
        // If there's other updater, wait for it
        if (!this.tokenRefreshInstance) {
          this.tokenRefreshInstance = flow(function* (this: JWTAuthProvider) {
            if (!this.tokenRefreshFn) return { access: '', refresh: '' };

            return yield this.tokenRefreshFn(this.tokens.refresh);
          }).bind(this)();

          try {
            const tokens = await this.tokenRefreshInstance;
            this.tokens = {
              access: tokens.access || '',
              refresh: tokens.refresh,
            };
          } catch (err) {
            this.user = EMPTY_USER;
            this.tokens = EMPTY_TOKENS;
            throw err;
          }

          this.tokenRefreshInstance = null;
        } else {
          await this.tokenRefreshInstance;
        }

        if (this.tokens.access && this.tokens.refresh) {
          return req({
            ...args,
            token: `Bearer ${this.tokens.access}`,
          });
        }

        this.user = EMPTY_USER;
        this.tokens = EMPTY_TOKENS;

        return e;
      });
}

const authProvider = new CustomJWTAuthProvider({
  layout: Layout,
  splash: '/images/splash.svg',
  resetPassword: ResetPassword,
  authRequestFn,
  tokenRefreshFn,
  authLogoutFn,
  authPasswRestoreFn,
  authPasswUpdateFn,
  loginLabel: 'Email',
  getUserName: ({ user }) =>
    `${user.surname} ${user.name?.charAt(0)} ${user.secondName?.charAt(0) || ''}`,
  getUserRoleTitle: (auth) => auth.user.email ?? '',
  getUserRole: (auth) => auth.user.role ?? UserRoles.admin,
});

export default authProvider;
