import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';
import { DefaultStatus } from '~/utils/constants';
import { uploadFile } from '~/utils/api';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const iconKey = data.icon ? await uploadFile({ file: data.icon, token }) : '';
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : '';
  const backgroundKey = data.background ? await uploadFile({ file: data.background, token }) : '';
  const colorSolutionsImageMap =
    data.colorSolutions &&
    (await Promise.all(
      data.colorSolutions.map(async (colorSolution) => {
        const modelKey = await uploadFile({ file: colorSolution.file, token });
        return {
          id: colorSolution.id,
          modelKey,
        };
      })
    ));
  const fittingsSolutionsImageMap =
    data.fittingSolutions &&
    (await Promise.all(
      data.fittingSolutions.map(async (fittingSolution) => {
        const modelKey = await uploadFile({ file: fittingSolution.file, token });
        return {
          id: fittingSolution.id,
          modelKey,
        };
      })
    ));

  const request = {
    name: data.name.trim(),
    status: DefaultStatus.Active,
    shortDescription: data.shortDescription?.trim(),
    fullDescription: data.fullDescription?.trim(),
    iconKey: iconKey || data.iconKey,
    imageKey: imageKey || data.imageKey,
    backgroundKey: backgroundKey || data.backgroundKey,
    infoGroupId: data.infoGroupId,
    sectionId: data.sectionId,
    fold: data.fold?.trim(),
    glassUnit: data.glassUnit?.trim(),
    fittings: data.fittings?.trim(),
    color: data.color?.trim(),
    colorSolutionsImageMap,
    fittingsSolutionsImageMap,
  };

  const result = await axios.post(url, request, {
    headers: { authorization: token },
  });

  return { data: result.data.data };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const iconKey = data.icon ? await uploadFile({ file: data.icon, token }) : '';
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : '';
  const backgroundKey = data.background ? await uploadFile({ file: data.background, token }) : '';
  const colorSolutionsImageMap =
    data.colorSolutions &&
    (await Promise.all(
      data.colorSolutions.map(async (colorSolution) => {
        const modelKey = await uploadFile({ file: colorSolution.file, token });
        return {
          id: colorSolution.id,
          modelKey,
        };
      })
    ));
  const fittingsSolutionsImageMap =
    data.fittingSolutions &&
    (await Promise.all(
      data.fittingSolutions.map(async (fittingSolution) => {
        const modelKey = await uploadFile({ file: fittingSolution.file, token });
        return {
          id: fittingSolution.id,
          modelKey,
        };
      })
    ));

  const request = {
    name: data.name.trim(),
    status: data.status,
    shortDescription: data.shortDescription?.trim(),
    fullDescription: data.fullDescription?.trim(),
    iconKey: iconKey || data.iconKey,
    imageKey: imageKey || data.imageKey,
    backgroundKey: backgroundKey || data.backgroundKey,
    infoGroupId: data.infoGroupId,
    sectionId: data.sectionId,
    fold: data.fold?.trim(),
    glassUnit: data.glassUnit?.trim(),
    fittings: data.fittings?.trim(),
    color: data.color?.trim(),
    colorSolutionsImageMap,
    fittingsSolutionsImageMap,
  };

  const result = await axios.put(`${url}/${data.id}`, request, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
