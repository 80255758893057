/* eslint-disable no-shadow */

export const ONLY_CYRILLIC_SYMBOLS = /^([а-яА-Я ]{0,500})$/;
export const REFERENCE_LIMIT = 9999;
export const API_URLS = {
  auth: {
    signin: '/v1/auth/signin',
    refresh: '/v1/auth/refresh',
    logout: '/v1/auth/logout',
    restore: '/v1/auth/restore',
  },
  user: {
    profile: '/v1/admin/profile',
    user: '/v1/admin',
  },
  colorSolution: '/v1/color-solutions',
  fittingSolution: '/v1/fitting-solutions',
  sections: '/v1/sections',
  infoGroups: '/v1/infogroups',
  infoPages: '/v1/infopages',
  upload: '/v1/file/upload',
};
export const DEFAULT_MIN_STRING_LENGTH = 1;
export const DEFAULT_MAX_STRING_LENGTH = 40;

export enum DefaultStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const DEFAULT_STATUS_NAMES = {
  [DefaultStatus.Active]: 'Активен',
  [DefaultStatus.Blocked]: 'Заблокирован',
};
