import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';

import { defaultMinMaxValidator, isEmail, minMax, regexpTest } from '~/utils/validators';
import {
  DEFAULT_MAX_STRING_LENGTH,
  DEFAULT_STATUS_NAMES,
  ONLY_CYRILLIC_SYMBOLS,
} from '~/utils/constants';
import { MAX_POSITION_LENGTH, PHONE_NUMBER_LENGTH } from '~/pages/user/constants';
import FullNameField from '~/pages/user/components/FullNameField';
import EntityButton from '~/common/components/EntityButton';
import PhoneField from '~/pages/user/components/PhoneInput';

export const USER_FIELDS: IEntityField[] = [
  // Editor
  {
    type: 'string',
    name: 'surname',
    label: 'Фамилия',
    required: true,
    hideInList: true,
    validator: (val: string) =>
      regexpTest(ONLY_CYRILLIC_SYMBOLS)('Обнаружены недопустимые символы')(val) ||
      defaultMinMaxValidator(val),
  },
  {
    type: 'string',
    name: 'name',
    label: 'Имя',
    required: true,
    hideInList: true,
    validator: (val: string) =>
      regexpTest(ONLY_CYRILLIC_SYMBOLS)('Обнаружены недопустимые символы')(val) ||
      defaultMinMaxValidator(val),
  },
  {
    type: 'string',
    name: 'secondName',
    label: 'Отчество',
    hideInList: true,
    validator: (val: string) =>
      minMax(0, DEFAULT_MAX_STRING_LENGTH)(`Должно быть до ${DEFAULT_MAX_STRING_LENGTH} символов`)(
        val
      ) || regexpTest(ONLY_CYRILLIC_SYMBOLS)('Обнаружены недопустимые символы')(val),
  },

  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'fullName',
    label: 'ФИО',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
    component: FullNameField,
  },
  {
    type: 'phone',
    name: 'phone',
    label: 'Номер телефона',
    required: true,
    hideInList: true,
    hideInEdit: true,
    validator: minMax(
      PHONE_NUMBER_LENGTH,
      PHONE_NUMBER_LENGTH
    )(`Должно быть ${PHONE_NUMBER_LENGTH} символов`),
    component: PhoneField,
  },
  {
    type: 'string',
    name: 'email',
    label: 'Почта',
    required: true,
    hideInList: true,
    hideInEdit: true,
    validator: isEmail('Невалидный адрес электронной почты'),
  },
  {
    type: 'string',
    name: 'position',
    label: 'Должность',
    validator: minMax(0, MAX_POSITION_LENGTH)(`Должно быть до ${MAX_POSITION_LENGTH} символов`),
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];
