import { EntityFieldReferenceSelect, IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { DEFAULT_MIN_STRING_LENGTH, DEFAULT_STATUS_NAMES } from '~/utils/constants';
import { minMax } from '~/utils/validators';
import {
  ALLOWED_BACKGROUND_EXTENSIONS,
  ALLOWED_ICON_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_MODEL_EXTENSIONS,
  MAX_BACKGROUND_SIZE,
  MAX_COLOR_LENGTH,
  MAX_FITTINGS_LENGTH,
  MAX_FOLD_LENGTH,
  MAX_FULL_DESCRIPTION_LENGTH,
  MAX_GLASS_UNIT_LENGTH,
  MAX_ICON_SIZE,
  MAX_IMAGE_SIZE,
  MAX_MODEL_SIZE,
  MAX_NAME_LENGTH,
  MAX_SHORT_DESCRIPTION_LENGTH,
} from '~/pages/infoPage/constants';
import EntityImage from '~/common/components/EntityImage';
import InfoPageFileUpload from '~/pages/infoPage/components/InfoPageFileUpload';
import { InfoPageColorSolutionList } from '~/pages/infoPage/components/InfoPageColorSolutionList';
import { InfoPageFittingSolutionList } from '~/pages/infoPage/components/InfoPageFittingSolutionList';
import { InfoPageColorSolutions } from '~/pages/infoPage/components/InfoPageColorSolutions';
import { InfoPageFittingSolutions } from '~/pages/infoPage/components/InfoPageFittingSolutions';

export const INFOPAGE_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`)(val?.trim()),
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'textarea',
    name: 'shortDescription',
    label: 'Краткое описание',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_SHORT_DESCRIPTION_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_SHORT_DESCRIPTION_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'textarea',
    name: 'fullDescription',
    label: 'Детальное описание',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_FULL_DESCRIPTION_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_FULL_DESCRIPTION_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
    options: {
      viewerStyle: { gridColumn: 'span 2' },
      editorStyle: { gridColumn: 'span 3' },
    },
  },
  {
    type: 'custom',
    name: 'icon',
    label: 'Иконка',
    options: {
      maxSize: MAX_ICON_SIZE,
      allowedMimeType: ALLOWED_ICON_EXTENSIONS,
      keyProp: 'iconKey',
    },
    hideInList: true,
    hideInView: true,
    component: InfoPageFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'iconUrl',
    label: 'Иконка',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка интерактивной модели',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    hideInList: true,
    hideInView: true,
    component: InfoPageFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'imageUrl',
    label: 'Картинка интерактивной модели',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },
  {
    type: 'custom',
    name: 'background',
    label: 'Картинка для заднего фона',
    options: {
      maxSize: MAX_BACKGROUND_SIZE,
      allowedMimeType: ALLOWED_BACKGROUND_EXTENSIONS,
      keyProp: 'backgroundKey',
    },
    hideInList: true,
    hideInView: true,
    component: InfoPageFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'backgroundUrl',
    label: 'Картинка для заднего фона',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },

  {
    type: 'referenceSelect',
    name: 'sectionId',
    label: 'Профиль',
    required: false,
    hideInList: true,
    component: EntityFieldReferenceSelect,
  },
  {
    type: 'string',
    name: 'fold',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_FOLD_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_FOLD_LENGTH} символов`)(val?.trim()),
    label: 'Количество камер',
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'glassUnit',
    label: 'Стеклопакет',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_GLASS_UNIT_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_GLASS_UNIT_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'fittings',
    label: 'Фурнитура',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_FITTINGS_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_FITTINGS_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'color',
    label: 'Исполнение',
    validator: (val: string): string =>
      minMax(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_COLOR_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_COLOR_LENGTH} символов`)(val?.trim()),
    required: false,
    hideInList: true,
  },
  {
    type: 'referenceSelect',
    name: 'colorSolutions',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInView: true,
    component: InfoPageColorSolutions,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'colorSolutionList',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: InfoPageColorSolutionList,
  },
  {
    type: 'referenceSelect',
    name: 'fittingSolutions',
    label: 'Доступные варианты фурнитуры',
    hideInList: true,
    hideInView: true,
    component: InfoPageFittingSolutions,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'fittingSolutionList',
    label: 'Доступные варианты фурнитуры',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: InfoPageFittingSolutionList,
  },
];
