import { Entity } from 'icerockdev-admin-toolkit';
import { API_URLS } from '~/utils/constants';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import { SECTION_FIELDS } from '~/pages/section/fields';
import SectionEntity from '~/pages/section/components/SectionEntity';
import { createItemsFn, getActiveColorSolutions, updateItemsFn } from '~/pages/section/api';

const SECTIONS_URL = API_URLS.sections;
const TITLE = 'Каталог профилей';

const Section = new SectionEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/sections',
    enabled: true,
  },
  api: {
    list: { url: SECTIONS_URL, method: 'get' },
    get: { url: SECTIONS_URL, method: 'get' },
    update: { url: SECTIONS_URL, method: 'put' },
    create: { url: SECTIONS_URL, method: 'post' },
  },
  fields: SECTION_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
  references: {
    colorSolutions: {
      getMany: (entity: Entity) => getActiveColorSolutions(entity),
    },
  },
});

export default Section;
