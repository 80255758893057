/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useCallback, useMemo } from 'react';
import { action, computed, flow, reaction, toJS } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { Link as RouterLink, Route, RouteComponentProps, Switch } from 'react-router-dom';
import {
  ENTITY_ERRORS,
  ENTITY_REFERENCE_FIELDS,
  IEntityCreateFunctionResult,
  IEntityGetFunctionResult,
  IEntityUpdateFunctionResult,
  IPageProps,
} from 'icerockdev-admin-toolkit';
import { Button } from '@material-ui/core';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { useTranslation } from 'react-i18next';
import CustomEntity, { ICustomEntityProps } from '~/common/modules/CustomEntity';
import { InfoPageBreadcrumbs } from '~/pages/infoPage/components/InfoPageBreadcrumbs';
import { InfoPageViewer } from '~/pages/infoPage/components/InfoPageViewer';
import { infoPageModules } from '~/pages/infoPage/constants';
import { InfoPageModules } from '~/pages/infoPage/components/InfoPageModules';
import { DefaultStatus } from '~/utils/constants';
import InfoGroup from '~/pages/infoGroup';

declare type InfoPageEntityMenu = IPageProps['menu'] & {
  parentUrl: string;
};

export interface InfoPageEntityProps extends ICustomEntityProps {
  parentTitle: string;
  menu: InfoPageEntityMenu;
}

class InfoPageEntity extends CustomEntity {
  emptyText = 'Для работы с информационными страницами в разделе создайте информационную страницу';

  deletionText = 'Вы уверены, что хотите удалить страницу?';

  blockText = 'Вы уверены, что хотите заблокировать страницу?';

  unblockText = 'Вы уверены, что хотите разблокировать страницу?';

  parentTitle = '';

  menu: InfoPageEntityMenu = { enabled: true, label: '', url: '', parentUrl: '' };

  constructor(fields?: Partial<InfoPageEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }

    reaction(
      () => this.editorData?.sectionId,
      () => this.getManySections()
    );

    reaction(
      () => this.editorData?.colorSolutions,
      () => this.getManyColorSolutions()
    );

    reaction(
      () => this.editorData?.fittingSolutions,
      () => this.getManyFittingSolutions()
    );
  }

  @action
  getManySections = async (): Promise<void> => {
    if (!this.references.sectionId.getMany) return;

    const results = await this.references.sectionId.getMany(this);

    Object.assign(this.referenceData, { sectionId: results });
  };

  @action
  getManyColorSolutions = async (): Promise<void> => {
    if (!this.references.colorSolutions.getMany) return;

    const results = await this.references.colorSolutions.getMany(this);

    Object.assign(this.referenceData, { colorSolutions: results });
  };

  @action
  getManyFittingSolutions = async (): Promise<void> => {
    if (!this.references.fittingSolutions.getOne) return;

    const results = await this.references.fittingSolutions.getOne(this);

    Object.assign(this.referenceData, { fittingSolutions: results });
  };

  parentEntityId = (): string => {
    const parentUrl = this.buildParentUrlFn('');
    const regExpExecArray = new RegExp(`${parentUrl}(\\d+)`).exec(window.location.pathname);
    return (regExpExecArray == null ? 0 : regExpExecArray[1]).toString();
  };

  buildUrl = (): string => `${this.menu.url}`.replace(`:infoGroupId`, this.parentEntityId);

  buildParentUrlFn = (id: string): string => `${this.menu.parentUrl}`.replace(`:infoGroupId`, id);

  buildParentUrl = (): string => this.buildParentUrlFn(this.parentEntityId());

  @action
  fetchItems = (): void => {
    this.fetchItemsInstance = flow(function* (this: InfoPageEntity) {
      this.isLoading = true;
      this.error = '';
      this.selected = [];

      try {
        // loading entity
        if (!this.api?.list?.url || !this.fetchItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        if (InfoGroup.getItemsFn) {
          const parentId = this.parentEntityId();
          const result: IEntityGetFunctionResult = yield this.parent?.auth?.withToken(
            InfoGroup.getItemsFn,
            {
              url: InfoGroup.api?.get?.url || '',
              id: parentId,
            }
          );

          if (!result || result.error)
            throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

          this.setEditorData({ ...this.editorData, infoGroupId: parentId });
          this.data = result?.data?.infoPages || [];
          this.filterData = {};
          this.totalCount = result?.data?.infoPages?.length || 0;
        }

        // Loading references (if any)
        const references = this.fields
          .filter(
            (field) =>
              field.type &&
              Object.prototype.hasOwnProperty.call(ENTITY_REFERENCE_FIELDS, field.type) &&
              this.references[field.name]?.getMany
          )
          .map(async (field) => ({
            [field.name]: await this.references[field.name].getMany(this),
          }));

        const refResults = yield Promise.all(references);

        this.referenceData = refResults.reduce(
          (obj: Record<string, any>, res: Record<string, any>) => ({
            ...obj,
            ...res,
          }),
          {}
        );

        // updating field reference data
        this.fields = this.fields.map((field) =>
          this.referenceData[field.name]
            ? {
                ...field,
                options: { ...field.options, referenceData: this.referenceData[field.name] },
              }
            : field
        );

        // finished
        this.isLoading = false;
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  updateItem = (): void => {
    this.updateItemInstance = flow(function* (this: InfoPageEntity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, false)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.update?.url || !this.updateItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(
          this.updateItemsFn,
          {
            url: this.api?.update?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();

        if (this.parent?.history?.length && this.parent?.history.goBack) {
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          this.parent?.history.push(this.buildParentUrl());
        }
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  createItem = (): void => {
    this.updateItemInstance = flow(function* (this: InfoPageEntity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();
        this.parent?.history.push(this.buildParentUrl());
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  deleteItem = async (id: number): Promise<'success' | undefined> => {
    // eslint-disable-next-line no-alert
    if (!this.parent?.auth?.withToken || !window.confirm(this.deletionText)) return;

    this.isLoading = true;

    try {
      await this.parent.auth.withToken(this.deleteItemsFn || (() => null), {
        url: `${this?.api?.delete.url}/${id}`,
      });

      this.fetchItems();
      this.parent?.history.push(this.buildParentUrl());
    } catch (e) {
      this.error = e;
      this.parent?.notifications.showError(e.message.toString());
    } finally {
      this.isLoading = false;
    }

    // eslint-disable-next-line consistent-return
    return 'success';
  };

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <InfoPageBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          parentName={this.parentTitle}
          parentId={this.parentEntityId()}
          url={this.buildUrl()}
          buildParentUrl={this.buildParentUrlFn}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={this.canEdit}
        />
      )
    );
  }

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <InfoPageViewer
          id={id}
          url={this.buildUrl()}
          errors={this.editorFieldErrors}
          onSave={() => {}}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing={false}
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          withToken={this.parent?.auth?.withToken}
          viewable={this.viewable}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const itemStatus = useMemo(() => this.editorData?.status, [id, this.editorData]);

      const { t } = useTranslation();

      const handleBlockOrDeleteButtonClick = useCallback(async () => {
        if (itemStatus === DefaultStatus.Blocked) {
          await this.deleteItem(id || 0);
        }
        if (itemStatus === DefaultStatus.Active) {
          await this.blockItem(id || 0);
          await this.getItem(id);
        }
      }, [id, itemStatus]);

      const handleUnblockButtonClick = useCallback(async () => {
        await this.unblockItem(id);
        await this.getItem(id);
      }, [id, itemStatus]);

      return (
        <>
          <Button
            color="primary"
            variant="contained"
            to={`${this.buildUrl()}/${id}/edit`}
            component={RouterLink}
            style={{ marginRight: 16 }}
          >
            {t('buttons:Edit')}
          </Button>
          <Button
            onClick={handleBlockOrDeleteButtonClick}
            variant="contained"
            color="secondary"
            type="button"
            style={{ marginRight: 10 }}
          >
            {itemStatus !== DefaultStatus.Active ? 'Удалить' : 'Блокировать'}
          </Button>
          {itemStatus === DefaultStatus.Blocked && (
            <Button
              onClick={handleUnblockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
              style={{ marginRight: 10 }}
            >
              Разблокировать
            </Button>
          )}
        </>
      );
    });
  }

  @computed
  get ViewerHead() {
    return observer(({ id }: { id: any }) => (
      <this.Breadcrumbs id={id} buttons={<this.ViewerHeadButtons id={id} />} />
    ));
  }

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <InfoPageModules
          modules={infoPageModules}
          errors={this.editorFieldErrors}
          url={this.buildUrl()}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get EditorBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <InfoPageModules
          modules={infoPageModules}
          id={id}
          errors={this.editorFieldErrors}
          url={this.buildUrl()}
          onSave={this.updateItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          withToken={this.parent?.auth?.withToken}
          viewable={this.viewable}
          entity={this}
          isEditing
        />
      );
    });
  }

  @computed
  get output() {
    return observer(() => (
      <Provider entity={this}>
        <Switch>
          <Route path={`${this.menu.url}/create`} component={this.Creator} />
          <Route
            path={`${this.menu.url}/:id/edit`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.Editor id={id} />}
          />
          <Route
            path={`${this.menu.url}/:id/`}
            component={({
              match: {
                params: { id },
              },
            }: RouteComponentProps<{ id: string }>) => <this.Viewer id={id} />}
          />
        </Switch>
      </Provider>
    ));
  }
}

export default InfoPageEntity;
