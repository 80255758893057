import { IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { defaultMinMaxValidator } from '~/utils/validators';
import { DEFAULT_STATUS_NAMES } from '~/utils/constants';
import {
  SectionFileUpload,
  SectionImageUpload,
} from '~/pages/section/components/SectionFileUpload';
import {
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_MODEL_EXTENSIONS,
  MAX_IMAGE_SIZE,
  MAX_IMAGES,
  MAX_MODEL_SIZE,
  SectionEnergyEfficiency,
} from '~/pages/section/constants';
import {
  maxOneHundredSymbols,
  maxOneThousandsSymbols,
  maxTenSymbols,
  maxThirtySymbols,
  maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
} from '~/pages/section/validators';
import { SectionColorSolutions } from '~/pages/section/components/SectionColorSolutions';
import { SectionMultyFileUpload } from '~/pages/section/components/SectionMultiFileUpload';
import { SectionColorSolutionList } from '~/pages/section/components/SectionColorSolutionList';
import EntityButton from '~/common/components/EntityButton';

export const SECTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    required: true,
    validator: defaultMinMaxValidator,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'number',
    name: 'countExamplesOfExecutions',
    label: 'Кол-во фото для примеров исполения',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'number',
    name: 'countExamplesOfConstructions',
    label: 'Кол-во фото для примеров конструкций',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'number',
    name: 'countColorSolutions',
    label: 'Кол-во цветовых решений',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'boolean',
    name: 'model3DExist',
    label: '3D модель',
    hideInCreate: true,
    hideInEdit: true,
    hideInView: true,
  },
  {
    type: 'custom',
    name: 'previewImg',
    label: 'Картинка для отображения в списковом виде',
    hideInList: true,
    required: true,
    component: SectionImageUpload,
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка для отображения в детальном виде профиля',
    hideInList: true,
    required: true,
    component: SectionImageUpload,
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
  },
  {
    type: 'textarea',
    name: 'certificates',
    label: 'Сертификаты и лицензии',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'select',
    name: 'energyEfficiencyClass',
    label: 'Энергоэффективность',
    options: {
      [SectionEnergyEfficiency.Aplusplusplus]: SectionEnergyEfficiency.Aplusplusplus,
      [SectionEnergyEfficiency.Aplusplus]: SectionEnergyEfficiency.Aplusplus,
      [SectionEnergyEfficiency.Aplus]: SectionEnergyEfficiency.Aplus,
      [SectionEnergyEfficiency.A]: SectionEnergyEfficiency.A,
      [SectionEnergyEfficiency.B]: SectionEnergyEfficiency.B,
      [SectionEnergyEfficiency.C]: SectionEnergyEfficiency.C,
      [SectionEnergyEfficiency.D]: SectionEnergyEfficiency.D,
    },
    hideInList: true,
  },
  {
    type: 'string',
    name: 'mountingWidth',
    label: 'Монтажная ширина (мм)',
    hideInList: true,
    validator: maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
  },
  {
    type: 'string',
    name: 'combinationWidth',
    label: 'Ширина комбинации и рама-створка в проеме (мм)',
    hideInList: true,
    validator: maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
  },
  {
    type: 'string',
    name: 'fold',
    label: 'Кол-во камер',
    hideInList: true,
    validator: maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
    required: true,
  },
  {
    type: 'string',
    name: 'sealingCircuitCount',
    label: 'Высота фальца (мм)',
    hideInList: true,
    validator: maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
  },
  {
    type: 'string',
    name: 'cellCount',
    label: 'Кол-во контуров уплотнения',
    hideInList: true,
    validator: maxTenSymbolsAndOnlyDigitsAndSpecialSymbols,
  },
  {
    type: 'string',
    name: 'doubleGlazedWindowHeight',
    label: 'Толщина стеклопакета (мм)',
    hideInList: true,
    validator: maxThirtySymbols,
  },
  {
    type: 'string',
    name: 'heatTransferResistance',
    label: 'Сопротивление теплопередаче (гКл/ч)',
    hideInList: true,
    validator: maxTenSymbols,
  },
  {
    type: 'textarea',
    name: 'gasketMaterial',
    label: 'Материал уплотнений',
    hideInList: true,
    validator: maxOneHundredSymbols,
  },
  {
    type: 'textarea',
    name: 'doubleGlazedWindowType',
    label: 'Тип стеклопакета',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'gasketColor',
    label: 'Цвета уплотнений',
    hideInList: true,
    validator: maxOneHundredSymbols,
  },
  {
    type: 'textarea',
    name: 'colorWays',
    label: 'Исполнения',
    hideInList: true,
    validator: maxOneHundredSymbols,
  },
  {
    type: 'textarea',
    name: 'shortDescription',
    label: 'Краткое описание для отображения в списковом виде профилей',
    hideInList: true,
    validator: maxOneHundredSymbols,
  },
  {
    type: 'textarea',
    name: 'advantages',
    label: 'Преимущества профиля',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'profileSystem',
    label: 'Профильная система',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'functionality',
    label: 'Функциональность',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'scope',
    label: 'Сфера применения',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'accessories',
    label: 'Дополнительные профили и принадлежности',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'physicalProperties',
    label: 'Физические свойства',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'heatTransferResistanceDescription',
    label: 'Сопротивление теплопередаче',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'seals',
    label: 'Уплотнения',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'glassUnit',
    label: 'Cтеклопакет',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'fittings',
    label: 'Используемая фурнитура',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'textarea',
    name: 'beams',
    label: 'Шпросы',
    hideInList: true,
    validator: maxOneThousandsSymbols,
  },
  {
    type: 'custom',
    name: 'model3D',
    label: 'Базовая 3D модель',
    hideInList: true,
    component: SectionFileUpload,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'referenceSelect',
    name: 'colorSolutions',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInView: true,
    component: SectionColorSolutions,
    options: {
      maxSize: MAX_MODEL_SIZE,
      allowedMimeType: ALLOWED_MODEL_EXTENSIONS,
    },
  },
  {
    type: 'custom',
    name: 'colorSolutionsUrl',
    label: 'Доступные цветовые решения',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: SectionColorSolutionList,
  },
  {
    type: 'custom',
    name: 'examplesOfExecutions',
    label: 'Примеры исполнений',
    hideInList: true,
    options: {
      maxFiles: MAX_IMAGES,
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    component: (props) => <SectionMultyFileUpload {...props} />,
  },
  {
    type: 'custom',
    name: 'examplesOfConstructions',
    label: 'Примеры конструкций',
    hideInList: true,
    options: {
      maxFiles: MAX_IMAGES,
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    component: (props) => <SectionMultyFileUpload {...props} />,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];
