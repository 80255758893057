import { IEntityField } from 'icerockdev-admin-toolkit';
import React from 'react';
import { DEFAULT_MIN_STRING_LENGTH, DEFAULT_STATUS_NAMES } from '~/utils/constants';
import { minMax, minMaxLength } from '~/utils/validators';
import {
  ALLOWED_IMAGE_EXTENSIONS,
  MAX_DESCRIPTION_LENGTH,
  MAX_IMAGE_SIZE,
  MAX_NAME_LENGTH,
} from '~/pages/infoGroup/constants';
import EntityImage from '~/common/components/EntityImage';
import InfoGroupFileUpload from '~/pages/infoGroup/components/InfoGroupFileUpload';
import EntityButton from '~/common/components/EntityButton';

export const INFOGROUP_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    validator: (val: string): string =>
      minMaxLength(
        DEFAULT_MIN_STRING_LENGTH,
        MAX_NAME_LENGTH
      )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${MAX_NAME_LENGTH} символов`)(val?.trim()),
    required: true,
  },
  {
    type: 'number',
    name: 'countInfoPages',
    label: 'Кол-во добавленных информационных страниц в раздел',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'number',
    name: 'countInfoPagesActive',
    label: 'Кол-во доступных для просмотра в приложении информационных страниц',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'number',
    name: 'countInfoPagesBlocked',
    label: 'Кол-во недоступных для просмотра в приложении информационных страниц',
    required: false,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
  },
  {
    type: 'textarea',
    name: 'description',
    label: 'Описание раздела',
    validator: (val: string): string =>
      minMax(0, MAX_DESCRIPTION_LENGTH)(`Должно быть до ${MAX_DESCRIPTION_LENGTH} символов`)(
        val?.trim()
      ),
    required: false,
    hideInList: true,
  },
  {
    type: 'boolean',
    name: 'descriptionExist',
    label: 'Наличие описания раздела',
    required: false,
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'custom',
    name: 'image',
    label: 'Картинка для отображения',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
    },
    hideInList: true,
    hideInView: true,
    component: InfoGroupFileUpload,
    required: true,
  },
  {
    type: 'custom',
    name: 'imageUrl',
    label: 'Картинка для отображения',
    hideInList: true,
    hideInCreate: true,
    hideInEdit: true,
    component: ({ value }) => <EntityImage width={32} height={32} val={value} />,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="block" />,
  },
  // delete button
  {
    type: 'custom',
    name: 'deleteButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <EntityButton {...props} type="delete" />,
  },
];

export const INFOGROUP_INFOPAGES_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    required: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: DEFAULT_STATUS_NAMES,
    hideInCreate: true,
    required: true,
  },
];
