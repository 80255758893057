import { isEmail as isEmailValidator } from 'validator';
import { DEFAULT_MAX_STRING_LENGTH, DEFAULT_MIN_STRING_LENGTH } from '~/utils/constants';

export const minMax =
  (min: number, max: number) =>
  (message: string) =>
  (val: string): string => {
    if (!val) return '';
    return val.length < min || val.length > max ? message : '';
  };

export const minMaxLength =
  (min: number, max: number) =>
  (message: string) =>
  (val: string): string => {
    const trimVal = val?.trim() ?? '';
    return !trimVal || trimVal.length < min || trimVal.length > max ? message : '';
  };

export const regexpTest =
  (regexp: RegExp) =>
  (message: string) =>
  (val: string): string => {
    if (!val) return '';
    return regexp.test(val) ? '' : message;
  };

export const isEmail =
  (message: string) =>
  (val: string): string =>
    !val || isEmailValidator(val) ? '' : message;

export const defaultMinMaxValidator = (val: string): string =>
  minMax(
    DEFAULT_MIN_STRING_LENGTH,
    DEFAULT_MAX_STRING_LENGTH
  )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${DEFAULT_MAX_STRING_LENGTH} символов`)(
    val?.trim()
  );
