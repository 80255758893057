/* eslint-disable @typescript-eslint/no-empty-function */
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Entity, IEntityField, IPageProps } from 'icerockdev-admin-toolkit';
import { Divider } from '@material-ui/core';
import { InfoGroupViewer } from '~/pages/infoGroup/components/InfoGroupViewer';
import { ICustomEntityProps } from '~/common/modules/CustomEntity';
import { InfoGroupCreateInfoPageButton } from '~/pages/infoGroup/components/InfoGroupCreateInfoPageButton';
import ExtendedCustomEntity from '~/common/modules/ExtendedCustomEntity';

declare type InfoGroupEntityMenu = IPageProps['menu'] & {
  childUrl: string;
};

interface InfoGroupEntityProps extends ICustomEntityProps {
  childEntity: Entity;
  childFields: IEntityField[];
  menu: InfoGroupEntityMenu;
}

class InfoGroupEntity extends ExtendedCustomEntity {
  childEntity: ExtendedCustomEntity = {} as ExtendedCustomEntity;

  @observable childFields: IEntityField[] = [];

  emptyText = 'Для начала работы создайте новый раздел';

  deletionText = 'Вы уверены, что хотите удалить раздел?';

  blockText = 'Вы уверены, что хотите заблокировать раздел?';

  unblockText = 'Вы уверены, что хотите разблокировать раздел?';

  menu: InfoGroupEntityMenu = { enabled: true, label: '', url: '', childUrl: '' };

  entityId = (): string => {
    const regExpExecArray = RegExp(`${this.menu.url}\\/(\\d+)`).exec(window.location.pathname);
    return (regExpExecArray == null ? 0 : regExpExecArray[1]).toString();
  };

  buildChildUrlFn = (id: string): string => `${this.menu.childUrl}`.replace(`:infoGroupId`, id);

  buildChildUrl = (): string => this.buildChildUrlFn(this.entityId());

  constructor(fields?: Partial<InfoGroupEntityProps>) {
    super();

    if (fields) {
      if (fields.childEntity === undefined) {
        throw Error('Add childEntity in InfoGroupEntity');
      }
      Object.assign(this, fields);
    }
  }

  @computed
  get ViewerHead() {
    return observer(({ id }: { id: any }) => (
      <this.Breadcrumbs
        id={id}
        buttons={
          <>
            <this.ViewerHeadButtons id={id} />
            <Divider style={{ margin: '0 16px 0 6px' }} orientation="vertical" flexItem />
            <InfoGroupCreateInfoPageButton url={this.buildChildUrlFn(id)} />
          </>
        }
      />
    ));
  }

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => (
      <InfoGroupViewer
        id={id}
        fields={this.fields}
        childFields={this.childFields}
        url={this.menu.url}
        childUrl={this.buildChildUrl()}
        errors={this.editorFieldErrors}
        onSave={() => {}}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing={false}
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        childData={this.editorData.infoPages ?? []}
        getItem={this.getItem}
        cancelGetItem={this.getItemsCancel}
        withToken={this.parent?.auth?.withToken}
        viewable={this.viewable}
        entity={this}
        childEntity={this.childEntity}
      />
    ));
  }
}

export default InfoGroupEntity;
