import { API_URLS, DefaultStatus } from '~/utils/constants';
import {
  blockItemsFn,
  deleteItemsFn,
  fetchItemsFn,
  getItemsFn,
  getManyReference,
  unblockItemsFn,
} from '~/utils/api';
import InfoPageEntity from './components/InfoPageEntity';
import { createItemsFn, updateItemsFn } from '~/pages/infoPage/api';
import { INFOPAGE_FIELDS } from '~/pages/infoPage/fields';

const INFOPAGE_URL = API_URLS.infoPages;
const TITLE = 'Информационные страницы';
const PARENT_TITLE = 'Разделы информационных страниц';

const InfoPage = new InfoPageEntity({
  title: TITLE,
  parentTitle: PARENT_TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/infogroups/:infoGroupId/infopages',
    parentUrl: '/infogroups/:infoGroupId',
    enabled: false,
  },
  api: {
    list: { url: INFOPAGE_URL, method: 'get' },
    get: { url: INFOPAGE_URL, method: 'get' },
    update: { url: INFOPAGE_URL, method: 'put' },
    create: { url: INFOPAGE_URL, method: 'post' },
    delete: { url: INFOPAGE_URL, method: 'delete' },
  },
  references: {
    sectionId: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.sections,
          entity,
          params: { status: DefaultStatus.Active },
        }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
        ),
    },
    colorSolutions: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.colorSolution,
          entity,
          params: { status: DefaultStatus.Active },
        }),
    },
    fittingSolutions: {
      getMany: (entity) =>
        getManyReference({
          url: API_URLS.fittingSolution,
          entity,
          params: { status: DefaultStatus.Active },
        }),
    },
  },
  fields: INFOPAGE_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default InfoPage;
