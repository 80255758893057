import {
  Entity,
  ENTITY_ERRORS,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionResult,
} from 'icerockdev-admin-toolkit';
import { action, computed, flow, observable, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import messages from '~/locales/ru/messages.json';
import ListFilters from '~/common/components/ListFilters';
import CustomEntity from '~/common/modules/CustomEntity';
// 'По вашему запросу подходящего пользователя в системе не найдено'
export class UserEntity extends CustomEntity {
  @observable emptyText =
    'В системе пока нет созданный пользователей. Для начала работы с каталогом пользователей создайте пользователя';

  deletionText = 'Вы уверены, что хотите удалить пользователя?';

  blockText = 'Вы уверены, что хотите заблокировать пользователя?';

  unblockText = 'Вы уверены, что хотите разблокировать пользователя?';

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(messages['Check all fields']);
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(messages['Items load failed']);
        }
        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || messages['Items load failed']);
        this.fetchItems();
        this.parent?.history.push(this.menu.url);
        this.parent?.notifications.showSuccess(
          `Пользователь ${result.data.surname} ${result.data.name} ${
            result.data.secondName || ''
          } успешно создан`
        );
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  updateItem = () => {
    if (!window.confirm('Вы уверены, что хотите редактировать пользователя?')) return;

    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, false)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.update?.url || !this.updateItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(
          this.updateItemsFn,
          {
            url: this.api?.update?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();

        if (this.parent?.history?.length && this.parent?.history.goBack) {
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          this.parent?.history.push(this.menu.url);
        }
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(
      () => [this.filters, this.sortBy, this.sortDir, this.page, this.items],
      this.setFiltersWindowHash
    );
    reaction(
      () => [this.filters],
      () => {
        this.emptyText = 'По вашему запросу подходящего пользователя в системе не найдено';
      }
    );
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();
  };

  @computed
  get ListHeadButtons() {
    return observer(() => <ListFilters />);
  }
}
