import { API_URLS } from '~/utils/constants';
import { blockItemsFn, deleteItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import InfoGroupEntity from './components/InfoGroupEntity';
import { createItemsFn, updateItemsFn } from '~/pages/infoGroup/api';
import { INFOGROUP_FIELDS, INFOGROUP_INFOPAGES_FIELDS } from '~/pages/infoGroup/fields';
import InfoPage from '~/pages/infoPage';

const INFOGROUP_URL = API_URLS.infoGroups;
const TITLE = 'Разделы информационных страниц';

const InfoGroup = new InfoGroupEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/infogroups',
    childUrl: InfoPage.menu.url,
    enabled: true,
  },
  api: {
    list: { url: INFOGROUP_URL, method: 'get' },
    get: { url: INFOGROUP_URL, method: 'get' },
    update: { url: INFOGROUP_URL, method: 'put' },
    create: { url: INFOGROUP_URL, method: 'post' },
  },
  childEntity: InfoPage,
  fields: INFOGROUP_FIELDS,
  childFields: INFOGROUP_INFOPAGES_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  deleteItemsFn,
  updateItemsFn,
});

export default InfoGroup;
