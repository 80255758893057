import React, { FC, Fragment, useMemo } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from '../InfoPageViewer/styles';
import EntityImage from '~/common/components/EntityImage';

const useStyles = makeStyles(styles);

const InfoPageFittingSolutionList: FC<IEntityFieldProps> = ({ data }) => {
  const classes = useStyles();

  const fields = useMemo(() => data?.fittingSolutions ?? [], [data?.id]);

  if (!fields || !fields.length) {
    return <div>Для данной информационной страницы отсутствует варианты фурнитуры.</div>;
  }

  return (
    <div className={classes.grid}>
      {fields &&
        fields.map((field) => (
          <Fragment key={`infoPageFittingSolutions-${field.id}`}>
            <div
              className={classes.field}
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}
            >
              <div>
                <div className="label">{field.name}</div>
              </div>

              {field.imageUrl && <EntityImage width={130} height={130} val={field.imageUrl} />}
              {field.modelUrl && <EntityImage width={130} height={130} val={field.modelUrl} />}
            </div>
          </Fragment>
        ))}
    </div>
  );
};

export { InfoPageFittingSolutionList };
